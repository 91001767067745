import React from "react";
import { Link } from "react-router-dom";

const TopNavbar = () => {
  return <div>
    {/* Topbar Start */}
  <div className="container-fluid bg-primary d-none d-lg-block" id="#top">
    <div className="container">
      <div className="row">
        <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
          <div className="d-inline-flex align-items-center">
            <a className="text-dark py-2 pe-3 border-end border-white" href="tel:+1 604 418 8416">
              <i className="bi bi-telephone text-secondary me-2" />
              +1 604 418 8416
            </a>
            <a className="text-dark py-2 px-3" href="mailto:yes.pa.int.inc@gmail.com">
              <i className="bi bi-envelope text-secondary me-2" />
              yes.pa.int.inc@gmail.com
            </a>
          </div>
        </div>
        <div className="col-md-6 text-center text-lg-end">
          <div className="d-inline-flex align-items-center">
            <a className="text-body py-2 px-3 border-end border-white" href="https://www.facebook.com/yespaint.ca/" target="_blank">
              <i className="fab fa-facebook-f text-white" />
            </a>
            <a className="text-body py-2 px-3 border-end border-white" href="https://twitter.com/yespaintca" target="_blank">
              <i className="fab fa-twitter text-white" />
            </a>
            <a className="text-body py-2 px-3 border-end border-white" href="">
              <i className="fab fa-linkedin-in text-white" />
            </a>
            <a className="text-body py-2 px-3 border-end border-white" href="https://www.instagram.com/yespaint.ca/" target="_blank">
              <i className="fab fa-instagram text-white" />
            </a>
            <a className="text-body py-2 ps-3" href="">
              <i className="fab fa-youtube text-white" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Topbar End */}
  </div>;
};

export default TopNavbar;
