import React from "react";
import BackToTopButton from "./BackToTop";

const Footer = () => {
  return (
    <div>
      {/* Footer Start */}
      <div className="container-fluid bg-dark bg-footer text-light py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-primary">Get In Touch</h4>
              <hr className="w-25 text-secondary mb-4" style={{ opacity: 1 }} />
              <p className="mb-4">
                Ready href start your project? Contact us for a vibrant
                collaboration today!
              </p>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt text-primary me-3" />
                14890 88a Ave. Surrey. British Columbia. V3R 7T3 Canada.
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope text-primary me-3" />
                <a
                  href="mailto:yes.pa.int.inc@gmail.com"
                  style={{ color: "white" }}
                >
                  yes.pa.int.inc@gmail.com{" "}
                </a>
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope text-primary me-3" />
                <a href="mailto:info@yespaint.ca" style={{ color: "white" }}>
                  info@yespaint.ca
                </a>
              </p>
              <p className="mb-0">
                <i className="fa fa-phone-alt text-primary me-3" />
                <a href="tel:+1 604 418 8416" style={{ color: "white" }}>
                  +1 604 418 8416
                </a>
              </p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-primary">Our Services</h4>
              <hr className="w-25 text-secondary mb-4" style={{ opacity: 1 }} />
              <div className="d-flex flex-column justify-content-start">
                <a className="text-light mb-2" href="#about">
                  <i className="fa fa-angle-right me-2" />
                  Air Conditioning
                </a>
                <a className="text-light mb-2" href="#about">
                  <i className="fa fa-angle-right me-2" />
                  Heating & Electrical
                </a>
                <a className="text-light mb-2" href="#about">
                  <i className="fa fa-angle-right me-2" />
                  Landscaping
                </a>
                <a className="text-light mb-2" href="#about">
                  <i className="fa fa-angle-right me-2" />
                  Plumbing & Repairs
                </a>
                <a className="text-light mb-2" href="#about">
                  <i className="fa fa-angle-right me-2" />
                  Property Care Co.
                </a>
                <a className="text-light mb-2" href="#about">
                  <i className="fa fa-angle-right me-2" />
                  Heating & Electrical
                </a>
                <a className="text-light" href="#about">
                  <i className="fa fa-angle-right me-2" />
                  Gravels Suppliers
                </a>
                <a className="text-light" href="#about">
                  <i
                    className="fa fa-angle-right me-2"
                    style={{
                      marginTop: "10px",
                    }}
                  />
                  Roofing & Cleaning
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-primary">Quick Links</h4>
              <hr className="w-25 text-secondary mb-4" style={{ opacity: 1 }} />
              <div className="d-flex flex-column justify-content-start">
                <a className="text-light mb-2" href="/">
                  <i className="fa fa-angle-right me-2" />
                  Home
                </a>
                <a className="text-light mb-2" href="#about">
                  <i className="fa fa-angle-right me-2" />
                  About Us
                </a>
                <a className="text-light mb-2" href="https://yespaint.ca/career/">
                  <i className="fa fa-angle-right me-2" />
                  Career
                </a>
                <a className="text-light mb-2" href="#services">
                  <i className="fa fa-angle-right me-2" />
                  Services
                </a>
                <a className="text-light mb-2" href="#contact">
                  <i className="fa fa-angle-right me-2" />
                  Get A Quote
                </a>
                <a
                  className="text-light mb-2"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="fa fa-angle-right me-2" />
                  Product News
                </a>
                <a
                  className="text-light mb-2"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#discount"
                >
                  <i className="fa fa-angle-right me-2" />
                  Discount
                </a>
                <a className="text-light mb-2" href="#team">
                  <i className="fa fa-angle-right me-2" />
                  Menbers
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-primary">Newsletter</h4>
              <hr className="w-25 text-secondary mb-4" style={{ opacity: 1 }} />
              <form action="">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control p-3 border-0"
                    placeholder="Your Email"
                    maxlength="30"
                    required
                  />
                  <button className="btn btn-primary">Sign Up</button>
                </div>
              </form>
              <h6 className="text-primary mt-4 mb-3">Follow Us</h6>
              <div className="d-flex">
                <a
                  className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"
                  href="https://twitter.com/yespaintca"
                >
                  <i className="fab fa-twitter" />
                </a>
                <a
                  className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"
                  href="https://www.facebook.com/yespaint.ca/"
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"
                  href="#"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
                <a
                  className="btn btn-lg btn-primary btn-lg-square rounded-circle"
                  href="https://www.instagram.com/yespaint.ca/"
                >
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-primary text-dark py-4">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-6 text-center text-md-start">
              <p className="mb-md-0">
                Copyright ©
                <a className="text-dark fw-bold" href="#">
                  www.yespaint.ca
                </a>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Product NEWS
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                We Use High Quality Paint Products From <br /> CLOVERDALE.
                SHERWIN WILLIAMS. AKZONOBEL JOTUN. FARROW & BALL. CANADIAN PAINT
                SUPPLIERS At Affordable Cost. We Supply The Same Directly To Our
                Clients And Customers Free From Additional Charges Accept Fair
                Labour Contract Of Painting.
              </p>

              <p>
                The Other Latest NEWS Regards To The Paint Industry
                Business.That Introduces New Japanese Technology Of Hands Free
                Wall Painting By Wall The Printer For Any Pattern Design Shape
                Or Photo Image To Restore On The Inner Wall Space.That Will Be
                Introduced Soon With Great Discounts and Benefits. 
                <br /> <br />
                Management Yes
                Paint Inc.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="discount"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                CASH - DISCOUNT - COUPONS - MEMBERS BENIFITS.
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            <p>
                In This Discounts Are Subjected To The Coupons, First Order,
                Members Of Yes Paint Company And Well Instant Cash Discount 
                Payments By The Company.ON FINISH OF WORK & Final Payment <br/> <br /> <br /> Yes Paint Inc.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <BackToTopButton />
      {/* Footer End */}
    </div>
  );
};

export default Footer;
