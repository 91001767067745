import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Quote = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8r9mias",
        "template_666q19d",
        form.current,
        "4esr31-tTmyF_zfRN"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message sent");
          // Reset the form fields after successful submission
          setNumber(""); // Clear the phone number state
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [number, setNumber] = useState("");

  const handleNumberInput = (e) => {
    // Ensure only numeric characters are allowed
    const value = e.target.value.replace(/[^0-9]/g, "");
    setNumber(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <div
        className="container-fluid bg-primary bg-quote py-5"
        id="contact"
        style={{ margin: "90px 0" }}
      >
        <div className="container py-5">
          <div className="row g-0 justify-content-start">
            <div className="col-lg-6">
              <div className="bg-white text-center p-5">
                <h1 className="mb-4">Contact Us</h1>
                <form ref={form} onSubmit={sendEmail}>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input
                        id="name"
                        name="to_name"
                        type="text"
                        className="form-control bg-light border-0"
                        placeholder="Contact Name"
                        style={{ height: 55 }}
                        min="1"
                        max="15"
                        maxlength="30"
                        // required
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="email"
                        id="email"
                        name="from_name"
                        className="form-control bg-light border-0"
                        placeholder="E-mail"
                        style={{ height: 55 }}
                        maxlength="30"
                        // required
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="tel"
                        id="numberInput"
                        name="phone"
                        value={number}
                        onChange={handleNumberInput}
                        className="form-control bg-light border-0"
                        placeholder="Contact Number"
                        style={{ height: 55 }}
                        maxlength="10"
                        pattern="[0-9]{10}"
                        // required
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="address"
                        name="address"
                        className="form-control bg-light border-0"
                        placeholder="Job location"
                        style={{ height: 55 }}
                        maxlength="45"
                        // required
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control bg-light border-0 py-3"
                        rows={2}
                        placeholder="Work Descriptions"
                        defaultValue={""}
                        name="message"
                        maxlength="150"
                      />
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                        onChange={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
